import { createApp, defineAsyncComponent } from "vue";
import axios from "axios";
import AOS from "aos";
import Tooltip from "bootstrap/js/dist/tooltip";

import "bootstrap/js/dist/collapse";
// import "bootstrap/js/dist/modal";
import "./fontawesome";

import { createI18n } from "vue-i18n";
import nb from "../lang/nb.json";
import en from "../lang/en.json";

import "v-calendar/dist/style.css";
import "aos/dist/aos.css";
import "../sass/app.scss";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { netflexCdnImagePlugin } from '@apility/vue-netflex-cdn-image'

import PageHeader from "./components/PageHeader.vue";
import PageHeaderSearch from "./components/PageHeaderSearch.vue";
import ToggleableBox from "./components/ToggleableBox";

import Ticketing from "./ticketing";
import Webshop from "./webshop";
import ChangeableDates from "./change-dates";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";

window.axios = axios;
window.axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content"),
};

const locale = document.querySelector("html").lang;
const messages = { nb, en };
const i18n = createI18n({
  locale,
  fallbackLocale: "nb",
  messages,
});

const app = createApp({
  components: {
    FontAwesomeIcon,
    PageHeader,
    PageHeaderSearch,
    SubNavbar: defineAsyncComponent(() =>
      import("./components/SubNavbar.vue")
    ),
    FormDatePicker: defineAsyncComponent(() =>
      import("./components/FormDatePicker.vue")
    ),
    ProgramCalendar: defineAsyncComponent(() =>
      import("./components/ProgramCalendar.vue")
    ),
    MerFilterListing: defineAsyncComponent(() =>
      import("./components/MerFilterListing.vue")
    ),
    DifficultyIndicator: defineAsyncComponent(() =>
      import("./components/DifficultyIndicator.vue")
    ),
    NewsletterConsentsCheckboxes: defineAsyncComponent(() =>
      import("./components/NewsletterConsentsCheckboxes.vue")
    ),
  },
})
  .use(Ticketing)
  .use(Webshop)
  .use(ChangeableDates)
  .use(i18n)
  .use(netflexCdnImagePlugin, {
    cdnUrl: 'st-vilvite2022.nf.cdn.netflexapp.com',
    breakpoints: {
      DEFAULT: 0,
      xs: 321,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
    compressor: 'lambda',
  })
  .provide("locale", locale)
  .component("v-toggleable-box", ToggleableBox)
  .mount("#app");

app.config = app?.config || {};
app.mixin = app?.mixin || (() => null);
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(
    import.meta.env.SENTRY_TRACES_SAMPLE_RATE || "0.001"
  ),
});

window.axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute("content"),
  "sentry-trace": document
    .querySelector("meta[name=sentry-trace]")
    ?.getAttribute("content"),
};

AOS.init();

const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]'
);
const tooltipList = [...tooltipTriggerList].map((el) => new Tooltip(el));

/*Newsletter logic */
window.addEventListener("DOMContentLoaded", function () {
  window.toggleSubCategories = function () {
    var schoolCheckbox = document.getElementById("newsletter-category-school");
    if (schoolCheckbox) {
      var subCategoriesWrapper = document.getElementById(
        "school-sub-checkboxes"
      );
      if (schoolCheckbox.checked) {
        subCategoriesWrapper.classList.remove("d-none");
      } else {
        subCategoriesWrapper.classList.add("d-none");
      }
    }
  };

  window.toggleSubCategories();
});

import Lightbox from 'bs5-lightbox';

const options = {
  keyboard: true,
  size: 'fullscreen'
};

document.querySelectorAll('.my-lightbox-toggle').forEach((el) => el.addEventListener('click', (e) => {
  e.preventDefault();
  const lightbox = new Lightbox(el, options);
  lightbox.show();
}));
